// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/database";

const firebaseConfig = {
  apiKey: "AIzaSyCEAW6KYDA3tKB5G4InillJFKg-rx4Go3s",
  authDomain: "jkedata-9c33c.firebaseapp.com",
  projectId: "jkedata-9c33c",
  storageBucket: "jkedata-9c33c.appspot.com",
  messagingSenderId: "133954739759",
  appId: "1:133954739759:web:2a6c8bdb5a4283b4fc30da",
  measurementId: "G-P5D7SS4R10"
};

firebase.initializeApp(firebaseConfig);
const database = firebase.database(); // Get the database reference

export { firebase, database };
