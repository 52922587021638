import siteLogoImg from "../pictures/logo.png";

export const siteName = "JKEDATA"; //this will display on sidebar
export const smsName = "jkedata"; // for bulk sms sending on behalf of the bussiness
export const appMainColor = "#1F5BFC"; // app's primary color
export const siteLogo = siteLogoImg; // site logo
export const baseApiUrl = "https://api.jkedata.com/"; // for api call
export const contactNumber = "08024444168";
export const contactEmail = "support@jkedata.com.ng";
export const contactAddress = "N0. 50 Zaria road, Kafur LGA, Katsina State.";
export const appname = "jkedataapp.apk";
export const appApkUrl = baseApiUrl + "download/" + appname;
export const whatsappChat = "https://wa.me/+2348024444168";
export const whatsappGroup = "https://chat.whatsapp.com/FqkOik7c4BFGEQXRcSUSxJ";
export const monnifyApiParams =
  "MK_TEST_LRPY0VY5Q0:WJ7FGVFZ2P5PWD4LD2ZUVWG8FU137SLQ";
